<template>
  <div class="MyMessage">
    <el-tabs @tab-click="handleClick">
      <el-tab-pane name="">
        <span slot="label"> 全 部 </span>
      </el-tab-pane>
      <el-tab-pane name="2">
        <span slot="label">平台公告</span>
      </el-tab-pane>
      <el-tab-pane name="1">
        <span slot="label">系统消息</span>
      </el-tab-pane>
    </el-tabs>
    <div class="topBott">
      <el-checkbox v-model="checked" @change="jkwd">仅看未读消息</el-checkbox>
      <el-button
        type="text"
        @click="allSee"
        :disabled="!dataList.length"
        style="margin-left: 100px"
        >全部已读</el-button
      >
    </div>

    <el-collapse @change="handleChange" accordion v-if="dataList.length">
      <el-collapse-item
        :name="item.noticeId"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <template slot="title">
          <div class="itemTit" :class="item.isRead == '1' ? 'hui' : ''">
            <span> {{ item.noticeTitle }}</span>
            <span> {{ item.createTime }}</span>
          </div>
        </template>
        <div class="itemCont" :class="item.isRead == '1' ? 'hui' : ''" v-html="item.noticeContent"></div>
        <el-link v-if="item.noticeType==2" class="link" :underline="false" :href="item.noticeUrl" type="primary">查看</el-link>
      </el-collapse-item>
    </el-collapse>
    <div style="text-align:center;color:#999;height:60px;" v-else>
      没有更多消息了
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryParams.pageNum"
        :page-size.sync="queryParams.pageSize"
        :page-sizes="[10, 50, 100, 200]"
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { noticeList, readStatus } from "@/api/message";
import noticeBus from "@/components/Header/noticeBus";
export default {
  name: "",
  data() {
    return {
      dataList: [],
      activeName: "",
      checked: false,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getlist();
  },
  beforeDestroy() {
    noticeBus.$emit("message", "");
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.name;
      this.getlist();
    },
    handleChange(val) {
      let param = {
        noticeIds: [val],
        userId: this.userInfo.userId,
      };
      readStatus(param);
    },
    getlist() {
      let param = {
        userId: this.userInfo.userId,
        noticeType: this.activeName,
        ...this.queryParams,
      };
      if (this.checked) {
        param.isRead = "0";
      } else {
        param.isRead = "";
      }
      noticeList(param).then((res) => {
        if (res.code == 200) {
          this.dataList = res.rows;
          this.total = res.total;
        }
      });
    },
    jkwd() {
      this.getlist();
    },
    // 当前页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getlist();
    },
    // 每页几条
    handleSizeChange(val) {
      this.pageSize = val;
      this.getlist();
    },
    allSee() {
      this.$confirm("要全部设为已读吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          isAllRead: 1,
          userId: this.userInfo.userId,
        };
        readStatus(param).then(({ code, msg }) => {
          if (code == 200) {
            this.$message.success(msg);
            noticeBus.$emit("message", "");
            this.getlist();
          } else {
            this.$message.error(msg);
          }
        });
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.MyMessage {
  margin-top: 20px;
  margin-left: 30px;
  .topBott {
    margin-bottom: 30px;
    margin-top: 0;
  }
}
.itemTit {
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemCont {
  padding: 0 30px;
}
.hui {
  color: #999;
}
.link{
  display: flex;
  justify-content: end;
}
.pagination {
  width: 100%;
  // background-color: #ccc;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
